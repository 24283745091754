/* You can add global styles to this file, and also import other style files */

.mat-menu-panel{
  min-height: 0px !important;
}

.ngx-file-drop__drop-zone{
  min-height: 150px;
}

button:focus{
  outline: none;
}
.mat-form-field.mat-focused .mat-form-field-ripple{
    background-color: #3498db;
}

.alert-form{
  .ng-star-inserted{
    color: white;
  }
  .alert-actions{
    float: right;
    margin-top: 5px;
  }
}

#toast-container>div {
  padding: 15px !important;
}
.mat-vertical-stepper-header .mat-step-icon {
  display: none !important;
}

.mat-paginator-range-label{
  display: none;
}

.btn-secondary {
  color: #fff;
  background: #6c757d;
  border-color: #6c757d;
  &:hover {
    background: #6c757d !important;
    border-color: #6c757d !important;
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1d8cf8 !important;
}
.mat-checkbox-ripple .mat-ripple-element {
  background-color: transparent !important;
}
.home{
  .mat-tab-header-pagination{
    display: none !important;
  }
}

.red-text {
  color: red !important;
}
